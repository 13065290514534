/* Horizontal scrollbar user record */
.horizontal-scrollable {
  margin: 5px 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  user-select: none;
}

.horizontal-scrollable > .row {
  min-height: 280px;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  align-items: center;
  margin-right: 30px !important;
  margin-left: 20px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Scroll buttons */
.arrowButtons {
  height: 207px;
  display: flex;
  position: absolute;
  align-items: center;
  cursor: pointer;
  color: white;
  z-index: 1;
  /* border-radius: 50%; */
  /* padding: 2px; */
  /* background: #625f68; */
  /* box-shadow: 0px 0px 10px 1px rgb(231, 225, 225) inset; */
}
.arrowButtons:hover {
  color: rgb(196, 16, 16);
  /* background: #ffffff; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.horizontal-scrollable > .row::-webkit-scrollbar {
  display: none;
  -webkit-overflow-scrolling: touch;
}
.cardView {
  position: relative;
  width: 290px;
  /* width: 368px; */
  /* height: 207px; */
  /* margin-left: 10px; */
  border: none;
  transition: 0.6s ease-in-out;
  background-color: transparent;
}
.m25m15 {
  margin: 25px 15px;
}
.m0m0 {
  margin: 0;
}
.cardView:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 25%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, rgb(0, 0, 0));
  /* box-shadow: 0px -50px 40px -10px black inset; */
  /* background:rgba(0,0,0,0.6); */
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.cardView:hover {
  /* transform: rotateY(20deg) rotateX(20deg); */
  transform: scale(1.1, 1.2);
  box-shadow: 0px 0px 6px 2px #5f5757;
  z-index: 99;
}
/* image card title */
.card-title {
  font-size: 15px;
  font-weight: 600;
  color: white;
  position: absolute;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
}

.card-duration {
  display: none;
  color: white;
  position: absolute;
  top: 0;
  right: 0%;
  background: rgba(0, 0, 0, 0.5);
  padding: 1px;
}
.cardView:hover .card-duration {
  display: block;
}
.cardView:hover #video-play-icon {
  display: block;
}
/* .cardView:hover:after {
    opacity:1;
} */
#video-play-icon {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 4rem;
  color: rgba(245, 241, 241, 0.8);
}
.user-record a {
  text-decoration: none;
  color: transparent;
  margin: 0;
}
.flickity-viewport {
  width: 100%;
}

@media only screen and (max-width: 1113px) {
  .cardView {
    width: 200px;
    margin: 5px 1px;
  }
  .card-image {
    width: 200px !important;
    height: 100px;
  }
}
@media only screen and (max-width: 1024px) {
  .cardView {
    width: 300px;
    margin: 25px 3px;
  }
  .card-image {
    width: 300px !important;
    height: 200px;
  }
  .card-title {
    font-size: 18px;
  }
  #video-play-icon {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 927px) {
  .cardView {
    width: 250px;
    margin: 15px 3px;
  }
  .card-image {
    width: 250px !important;
  }
  .card-title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 777px) {
  .cardView {
    width: 180px;
    margin: 5px 3px;
  }
  .card-image {
    width: 180px !important;
    height: 100px;
  }
  .card-title {
    font-size: 14px;
  }
}
@media only screen and (max-width: 568px) {
  .card-image {
    width: 200px !important;
    height: 100px;
  }
  .arrowButtons {
    display: none !important;
  }
  .cardView {
    width: 200px;
    margin: 5px 5px;
  }
  .card-title {
    font-size: 12px;
    padding: 0 3px;
  }
  .horizontal-scrollable > .row {
    min-height: 125px;
    margin: 0 1px !important;
  }
}
@media only screen and (max-width: 430px) {
  .cardView {
    width: 190px;
    margin: 5px 2px;
  }
  .card-image {
    width: 190px !important;
    height: 80px;
  }
}

@media only screen and (max-width: 387px) {
  .cardView {
    width: 150px;
    margin: 2px 3px;
  }
  .card-image {
    width: 150px !important;
    height: 100px;
  }
  #video-play-icon {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 311px) {
  .cardView {
    width: 240px;
    margin: 10px 3px;
  }
  .card-image {
    width: 240px !important;
    height: 100px;
  }
}
