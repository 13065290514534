@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Italianno&display=swap);
.usersigninContainer h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}
.usersigninContainer table tr :hover {
  background-color: rgb(221, 221, 221);
}
.signinContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  flex-direction: column;
}
.heading{
    font-family: 'Italianno', cursive;
    font-size: 60px;
}
.inputfields {
  width: 200px;
  margin-top: 70px;
  margin-left: 35%;
  padding: 20px;
  
}
.inputfield:focus{
    outline: none;
}
.inputfield{
    padding: 18px;
    border: none;
    margin:3px;
    height: 15px;
    width:400px;
    font-size: 16px;
    background-color: rgb(244, 244, 243);
    outline: none;
    text-decoration: none;
    color: rgb(76, 82, 100);
    border-radius: 5px;
}

.submitButtons{
    padding: 10px;
    width: 400px;
   border:none;
  
    font-size: 16px;
    color:white;
    background-color: rgb(69, 106, 221);
}

.main-footer {
  background-color: black;
  color: white;
  /* margin-top: 3em; */
  padding: 100px 0;
}
.main-footer a {
  color: white !important;
  font-size: 1rem !important;
  text-decoration: none !important;
}
.footer-bottom {
  text-align: center;
}
.social-icons {
  margin-left: 1.2rem;
}
.social-icons a {
  font-size: 1.5rem !important;
  padding-right: 1rem;
  text-align: center;
}

.AllCategoryVideos {
  /* margin : 0 50px; */
  padding-top: 4rem;
  background: black;
}

#cat-img-name {
  color: #fff;
  font-weight: 700;
  padding: 2% 0 0 2%;
  margin: 0;
  text-align: left;
}

.catImgCard {
  height: 55vh;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  margin-bottom: 20px;
}
.catImgCard::after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
#cat-name {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 2.2rem;
}
.catImgCard:hover:after {
  opacity: 0.7;
}
.all-video-container {
  /* padding: 0 5px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.videog {
  margin-bottom: 2%;
}
@media only screen and (max-width: 1473px) {
  .main-video-container {
    margin: 0 5px;
  }
}
@media only screen and (max-width: 413px) {
  .main-video-container {
    margin: 0;
  }
}

/* Horizontal scrollbar user record */
.horizontal-scrollable {
  margin: 5px 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-user-select: none;
          user-select: none;
}

.horizontal-scrollable > .row {
  min-height: 280px;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  align-items: center;
  margin-right: 30px !important;
  margin-left: 20px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Scroll buttons */
.arrowButtons {
  height: 207px;
  display: flex;
  position: absolute;
  align-items: center;
  cursor: pointer;
  color: white;
  z-index: 1;
  /* border-radius: 50%; */
  /* padding: 2px; */
  /* background: #625f68; */
  /* box-shadow: 0px 0px 10px 1px rgb(231, 225, 225) inset; */
}
.arrowButtons:hover {
  color: rgb(196, 16, 16);
  /* background: #ffffff; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.horizontal-scrollable > .row::-webkit-scrollbar {
  display: none;
  -webkit-overflow-scrolling: touch;
}
.cardView {
  position: relative;
  width: 290px;
  /* width: 368px; */
  /* height: 207px; */
  /* margin-left: 10px; */
  border: none;
  transition: 0.6s ease-in-out;
  background-color: transparent;
}
.m25m15 {
  margin: 25px 15px;
}
.m0m0 {
  margin: 0;
}
.cardView:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 25%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, rgb(0, 0, 0));
  /* box-shadow: 0px -50px 40px -10px black inset; */
  /* background:rgba(0,0,0,0.6); */
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.cardView:hover {
  /* transform: rotateY(20deg) rotateX(20deg); */
  transform: scale(1.1, 1.2);
  box-shadow: 0px 0px 6px 2px #5f5757;
  z-index: 99;
}
/* image card title */
.card-title {
  font-size: 15px;
  font-weight: 600;
  color: white;
  position: absolute;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
}

.card-duration {
  display: none;
  color: white;
  position: absolute;
  top: 0;
  right: 0%;
  background: rgba(0, 0, 0, 0.5);
  padding: 1px;
}
.cardView:hover .card-duration {
  display: block;
}
.cardView:hover #video-play-icon {
  display: block;
}
/* .cardView:hover:after {
    opacity:1;
} */
#video-play-icon {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 4rem;
  color: rgba(245, 241, 241, 0.8);
}
.user-record a {
  text-decoration: none;
  color: transparent;
  margin: 0;
}
.flickity-viewport {
  width: 100%;
}

@media only screen and (max-width: 1113px) {
  .cardView {
    width: 200px;
    margin: 5px 1px;
  }
  .card-image {
    width: 200px !important;
    height: 100px;
  }
}
@media only screen and (max-width: 1024px) {
  .cardView {
    width: 300px;
    margin: 25px 3px;
  }
  .card-image {
    width: 300px !important;
    height: 200px;
  }
  .card-title {
    font-size: 18px;
  }
  #video-play-icon {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 927px) {
  .cardView {
    width: 250px;
    margin: 15px 3px;
  }
  .card-image {
    width: 250px !important;
  }
  .card-title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 777px) {
  .cardView {
    width: 180px;
    margin: 5px 3px;
  }
  .card-image {
    width: 180px !important;
    height: 100px;
  }
  .card-title {
    font-size: 14px;
  }
}
@media only screen and (max-width: 568px) {
  .card-image {
    width: 200px !important;
    height: 100px;
  }
  .arrowButtons {
    display: none !important;
  }
  .cardView {
    width: 200px;
    margin: 5px 5px;
  }
  .card-title {
    font-size: 12px;
    padding: 0 3px;
  }
  .horizontal-scrollable > .row {
    min-height: 125px;
    margin: 0 1px !important;
  }
}
@media only screen and (max-width: 430px) {
  .cardView {
    width: 190px;
    margin: 5px 2px;
  }
  .card-image {
    width: 190px !important;
    height: 80px;
  }
}

@media only screen and (max-width: 387px) {
  .cardView {
    width: 150px;
    margin: 2px 3px;
  }
  .card-image {
    width: 150px !important;
    height: 100px;
  }
  #video-play-icon {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 311px) {
  .cardView {
    width: 240px;
    margin: 10px 3px;
  }
  .card-image {
    width: 240px !important;
    height: 100px;
  }
}

* {
  box-sizing: border-box;
}

.disp-hide {
  display: none;
}

.user-home {
  width: 100%;
  padding-top: 70px;
  overflow: auto;
  background: white;
    /* background: linear-gradient(to bottom, #fff 10%, #23b5b5 90%); */
    color: #000;
  min-height: 100vh;
  /* -webkit-overflow-scrolling: touch; */
}

.carousel-title {
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 40px;
  text-align: center;
  font-weight: 900;
  font-size: 1.6rem;
  color: #ffffff;
}

.carousel-shadow-bottom {
  width: 100%;
  height: 25%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.01)
  );
}

.carousel-shadow-top {
  width: 100%;
  height: 12%;
  position: absolute;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.01)
  );
}

.pos-rel {
  position: relative;
}

.bot {
  position: relative;
  bottom: -125px;
}

.categories {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.left, .right {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -388%;
  z-index: 1;
  display: none;
  transition: display 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: display 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.left {
  left: 0%;
}

.right {
  right: 0%;
}

.left:hover,
.right:hover {
  cursor: pointer;
}

.unhide {
  display: block;
}

.video-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  display: none;
}

.cardtitle,
.card-duration {
  position: absolute;
  color: #ffffff;
}

.carousel-img {
  width: 65vw;
  /* object-fit: contain;
  max-height: 600px; */
}

.this {
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: color;
}

.this:hover {
  color: grey;
  cursor: pointer;
}

.cat-name {
  /* position: relative; */
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  padding: 0% 1%;
  margin-top: 1.3%;
  font-weight: 700;
  background: transparent;
  color: #000000;
}

.cardtitle {
  font-size: 15px;
  font-weight: 600;
  bottom: 4%;
  z-index: 1;
  text-align: center;
  width: 290px;
}

.cardtitle-shadow {
  width: 290px;
  height: 25%;
  position: absolute;
  bottom: 11%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.01)
  );
}

.card-duration {
  display: none;
  top: 9%;
  right: 0;
  font-size: 0.7rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 1px;
}

.video:hover .card-duration {
  display: block;
}

.category {
  margin-top: 0.3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 210px;
  overflow: hidden;
  /* user-select: none; */
}

.side-space {
  transform: translateX(250px);
}

.side-space-1 {
  transform: translateX(-320px);
}

.slob {
  width: 220px;
  height: 100%;
}

.child {
  width: -webkit-max-content;
  width: max-content;
  background: transparent;
  display: flex;
  flex-direction: row;
  height: 100%;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* -webkit-overflow-scrolling: touch; */
}

.child.drag {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.video {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform;
}

.video:hover {
  transform: scale(1.15);
}

.video:hover .video-btn {
  display: block;
}

.img-thumb {
  width: 290px;
  object-fit: contain;
}

.outer-video {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;

}

@media only screen and (max-width: 540px) {
  .cardtitle {
    font-size: 10px;
    width: 170px;
    bottom : 2%;
  }

  .cardtitle-shadow {
    width: 170px;
    bottom: 10%;
  }

  .card-duration {
    font-size: 0.6rem;
  }

  .img-thumb {
    width: 170px;
  }

  .video-btn {
    width: 30px;
    height : 30px;
  }

  .left, .right {
    width: 30px;
    height: 30px;
    bottom: -350%;
  }

  .category {
    height : 120px;
  }

  .outer-video {
    margin: 0px 8px;
  }

  .carousel-img {
    width : 80vw; 
  }

  .carousel-title {
    font-size: 15px;
    bottom: 17px;
  }

  .cat-name {
   font-size: 14px;
   margin-top: 20px; 
  }

  .bot {
    bottom: -72px;
  }
}
.al-vid-cont {
  color: #fff;
  background: black;
}
.al-vid-cont h2 {
  padding: 1% 2%;
}
.al-vid-cont .play-vid {
  /* padding: 0; */
  margin: 0 auto;
  padding-top: 4.5rem;
  /* width: 1000px !important; */
  width: 1188px !important;
  /* height: 500px !important; */
  height: 668px !important;
}
#video-details {
  display: flex;
  justify-content: space-between;
  margin: 15px 20%;
}
#video-details-left {
  width: 70%;
  overflow-wrap: anywhere;
}
#video-details-left span {
  display: flex;
}
#video-details-right span {
  display: flex;
}
#video-details-left h3,
p {
  padding-left: 3px;
}
#video-details-right p {
  padding-left: 3px;
}
/* animation color */
.MuiSkeleton-wave::after {
  background-color: rgba(255, 255, 255, 0.04) !important;
}
@media only screen and (max-width: 1679px) {
  #video-details {
    margin: 15px 15%;
  }
}
@media only screen and (max-width: 1490px) {
  #video-details {
    margin: 15px 12%;
  }
}
@media only screen and (max-width: 1270px) {
  .al-vid-cont h2 {
    padding: 20px 40px;
  }
  #video-details {
    margin: 15px 8%;
  }
}

@media only screen and (max-width: 1047px) {
  .al-vid-cont .play-vid {
    width: 95% !important;
  }
  #video-details {
    margin: 15px 5%;
    font-size: 20px;
  }
  #video-details h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .al-vid-cont .play-vid {
    height: 40vh !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .al-vid-cont .play-vid {
    width: 100% !important;
  }
  .al-vid-cont h2 {
    padding: 10px 30px;
  }
  #video-details {
    margin: 15px 3%;
  }
}
@media only screen and (max-width: 540px) {
  .al-vid-cont h2 {
    padding: 0px 15px;
    margin: 0;
  }
  #video-details {
    font-size: 12px;
  }
  #video-details h3 {
    font-size: 17px;
  }
}

.error-red{
    color: red;
}
.custom-image-input {
  position: absolute;
  color: transparent;
  cursor: pointer;
  width: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  height: calc(1em + 0.75rem);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-image-input::-webkit-file-upload-button {
  visibility: hidden;
}
.course-item {
  height: 200px;
  width: 500px;
  display: flex;
  color: #000;
  background-color: rgb(231, 215, 121);
  margin-bottom: 20px;
}
.course-item-left {
  width: 50%;
  height: 100%;
}
.course-item-left img {
  width: 100%;
  height: 100%;
}
.course-item-right {
  position: relative;
  display: flex;
  padding: 5px;
  flex-direction: column;

  width: 100%;
}
.course-item-right-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: #424949;
  background-color: #ecf0f1;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  position: relative;
}

h1 {
  text-align: center;
  height: 38px;
  margin: 60px 0;
}
h1 span {
  white-space: nowrap;
}

.flex-parent {
  position: absolute;
  color: white;
  margin-left: 210px;
  bottom: 50px;
  width:50%;
  margin-left: 25%;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
}

.input {
  width: 25px;
  height: 25px;
  background-color: #2c3e50;
  position: relative;
  border-radius: 50%;
}
.input:hover {
  cursor: pointer;
}
.input::before,
.input::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2c3e50;
  width: 4vw;
  height: 5px;
  max-width: 50px;
}
.input::before {
  left: calc(-4vw + 12.5px);
}
.input::after {
  right: calc(-4vw + 12.5px);
}
.input.active {
  background-color: #2c3e50;
}
.input.active::before {
  background-color: #2c3e50;
}
.input.active::after {
  background-color: #aeb6bf;
}
.input.active span {
  font-weight: 700;
}
.input.active span::before {
  font-size: 13px;
}
.input.active span::after {
  font-size: 15px;
}
.input.active ~ .input,
.input.active ~ .input::before,
.input.active ~ .input::after {
  background-color: #aeb6bf;
}
.input span {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}
.input span::before,
.input span::after {
  visibility: visible;
  position: absolute;
  left: 50%;
}
.input span::after {
  content: attr(data-year);
  top: 25px;
  transform: translateX(-50%);
  font-size: 14px;
}
.input span::before {
  content: attr(data-info);
  top: -65px;
  width: 70px;
  transform: translateX(-5px) rotateZ(-45deg);
  font-size: 12px;
  text-indent: -10px;
}

.description-flex-container {
  width: 80vw;
  font-weight: 400;
  font-size: 22px;
  margin-top: 100px;
  max-width: 1000px;
}
.description-flex-container p {
  margin-top: 0;
  display: none;
}
.description-flex-container p.active {
  display: block;
}

@media (min-width: 1250px) {
  .input::before {
    left: -37.5px;
  }

  .input::after {
    right: -37.5px;
  }
}
@media (max-width: 850px) {
  .input {
    width: 17px;
    height: 17px;
  }
  .input::before,
  .input::after {
    height: 3px;
  }
  .input::before {
    left: calc(-4vw + 8.5px);
  }
  .input::after {
    right: calc(-4vw + 8.5px);
  }
}
@media (max-width: 600px) {
  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 15vh;
  }

  .input {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
    background-color: #aeb6bf;
  }
  .input::before,
  .input::after {
    content: none;
  }
  .input span {
    width: 100%;
    height: 100%;
    display: block;
  }
  .input span::before {
    top: calc(100% + 5px);
    transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }
  .input span::after {
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ecf0f1;
  }

  .description-flex-container {
    margin-top: 30px;
    text-align: center;
  }
}
@media (max-width: 400px) {
  body {
    min-height: 950px;
  }
}

.suggested-item:hover {
    background-color: rgb(0, 62, 231);
}
.MuiAppBar-colorPrimary {
  background-color: #15171c !important;
}
.MuiIconButton-label {
  color: white !important;
}

.MuiList-root {
  color: white !important;
  background-color: #15171c !important;
}
.MuiPaper-root
  .MuiDrawer-paper
  .makeStyles-drawerClose-88
  .MuiDrawer-paperAnchorLeft
  .MuiDrawer-paperAnchorDockedLeft
  .MuiPaper-elevation0 {
  background-color: #15171c !important;
}
a {
  text-decoration: none !important;
}
@media (min-width: 600px) {
  .makeStyles-toolbar-9 {
    min-height: 0px !important;
  }
}
.MuiListItemText-root {
  margin-bottom: 23px !important;
}


html::-webkit-scrollbar {
  display: none;
  -webkit-overflow-scrolling: touch;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* login signup css */
.loginForm {
  padding: 20px 20px 10px 20px;
  max-width: 502px;
  margin-top: 30px;
}
.loginForm a {
  text-decoration: none;
  font-weight: 700;
  color: rgb(230, 86, 19);
  font-size: 1rem !important;
}
.loginGrid {
  height: 100vh;
  padding: 30px 5px;
  background-color: #000;
}
@media only screen and (max-width: 413px) {
  .loginGrid {
    height: 100%;
  }
}
/* end */

.login {
  width: 100%;
  min-height: 100vh;
  min-width: 520px;
  padding: 0 20px;
  background: #e9e9e9;
  display: flex;
}

.login .loginContainer {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer label {
  color: white;
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px 40px 10px 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  letter-spacing: 1px;
}

.login .loginContainer h1 {
  text-align: center;
  color: #fff;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .errorMsg {
  margin-top: 15px;
  color: red;
  font-size: 16px;
}

.home {
  width: 100%;
  min-height: 100vh;
  /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
  background: black;
}

.navbar {
  padding: 0;
  position: fixed;
  width: 100vw;
  z-index: 22;
  color: #e9e9e9;
  margin-bottom: 250px;
}
.navbar a {
  position: absolute;
  left: 8%;
  color: #fff;
  font-size: 1.5rem !important;
}
.navbar a:hover {
  text-decoration: none;
  color: #c04848;
}
.navbar nav {
  background: #603bbb;
  width: 100%;
  padding: 8px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar nav button {
  width: 86px;
  padding: 8px 0;
  background: #3a2b58;
}
/* Profile picture */
#user-name {
  position: relative;
  left: 60%;
}
/* #user-name div .name {
  border: 1px solid black;
} */
#user-avatar {
  position: relative;
  left: 15%;
}
#avatar {
  margin-top: -10px !important;
  height: 40px;
  width: 50px;
  border-radius: 50%;
}

.video-model {
  height: 50vh;
  width: 50vh;
}

/* home category name */
.category-name {
  margin-left: 10px;
  color: white;
  -webkit-user-select: none;
          user-select: none;
  font-weight: 900;
  font-size: 1.2rem;
}
.home-content {
  padding-top: 30px;
}
.user-profile {
  height: 200px;
}
.loginContainer .user-profile img {
  position: relative;
  left: 90px;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background-color: #ffffff;
  border: 1px solid red;
}
.loginContainer .user-profile input {
  position: relative;
  top: -71px;
  left: -110px;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  /* background-color: transparent; */
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
#profile-progress {
  width: 130%;
  position: relative;
  top: -69px;
  left: -60px;
}

/* #profile-progress #progress-bar {
  width: 100%;
  position: relative;
  top: 22px;
} */
/* #profile-progress p {
  position: relative;
  text-align: center;
  top: -6px;
} */
.user-profile button {
  position: relative;
  bottom: 125px;
  left: 320px;
  width: 80px;
}
.profileContainer {
  margin-top: 75px !important;
  padding: 13px 60px !important;
}

.video-progress {
  position: relative;
  top: -16px;
}
.thumbnail-progress {
  position: relative;
  top: -16px;
}
#modal-thumbnail {
  height: 100px;
}
.text-success {
  display: flex;
  margin: auto;
}
.text-primary {
  display: flex;
  margin: auto;
}
hr {
  border-top: 1px solid rgba(255, 255, 255, 0.57) !important;
}

.main-carousel {
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
}
.carousel {
  max-height: 70vh;
}
.img:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1));
  /* box-shadow: 15px 0px 100px 50px black inset; */
  /* background:rgba(0,0,0,0.6); */
  /* opacity: 1; */
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

@media only screen and (max-width: 522px) {
  #signUpName {
    width: 100%;
  }
  .main-carousel {
    margin-top: 50px;
    width: 100%;
  }
  .margin-top {
    margin-top: 50px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    top: 35px !important;
  }
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

* {
  box-sizing: border-box;
}

/* .flickity-enabled {
  top: 4rem !important;
} */

.header-container {
  color: white;
  background-color: #15171c;
  
  width: 100%;
  height: 4rem;
  /* position: fixed; */
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 0% 1%;
  margin-bottom: 2vw;
}

.cat {
  padding: 0% 1%;
  color: white;
  transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: color;
}
.cat a {
  color: white;
}

.cat:hover {
  cursor: pointer;
}
.cat a:hover {
  color: #c7c7c7;
  text-decoration: none;
}
.exp-text {
  margin-left: 150px;
  margin-right: 25px;
  font-size: 1.3rem;
  padding: 0% 1% 0% 1%;
}
.exp-text a:first-child {
  margin-right: 1vw;
}
.exp-text a {
  text-decoration: none;
  color: inherit;
}

.to-right {
  display: flex;
  align-items: center;
  position: fixed;
  right: 100px;
  float: right;
}
.to-right .explified .logout-button {
  display: none;
  position: fixed;
  color: #23b5b5 !important;
  background-color: #3a2b58 !important;
}
.to-right .explified:hover .logout-button {
  display: block;
}

.user-search input {
  outline: none;
  border: 0;
  /* height: 1.2rem; */
  background: transparent;
  width: 200px;
  font-size: 0.9rem;
  /* color: #ffffff; */
  border-radius: 5px;
  padding: 4px 16px 4px 5px;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-search input:focus,
.user-search input:hover {
  border: 1.5px solid #23b5b5;
}

.allow-pad {
  padding: 0px 25px;
}

.exp-text:hover,
.explified:hover,
.exp-logo:hover {
  cursor: pointer;
}
.exp-text a:hover {
  text-decoration: none;
  color: inherit;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: none;
  /* padding: 0% 1%; */
  outline: none;
}

.line {
  fill: none;
  stroke: #ffffff;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

@media only screen and (max-width: 860px) {
  .menu {
    display: block;
  }

  .cat {
    display: none;
  }

  .explified {
    display: none;
  }

  .logout-but {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flickity-page-dots {
  bottom: -20px !important;
  z-index: 1 !important;
}

.flickity-page-dots
.dot {
    /* background: #ffffff !important; */
    opacity: 1 !important;
}

.flickity-page-dots
.dot.is-selected {
    background: #ff0b0a !important;
}

.carousel {
  /* background: black !important; */
}

@media only screen and (max-width : 540px) {
  .flickity-page-dots {
    bottom: 5px !important;
  }

  .flickity-page-dots
  .dot {
    width: 7px;
    height : 7px;
  }
}
.firebaseui-card-header h1 {
  color: black !important;
}

.firebaseui-card-actions button {
  width: auto !important;
}
.mdl-textfield--floating-label .mdl-textfield__label {
  margin: 0px !important;
  font-size: 1rem;
  padding-left: 2px;
}
.firebaseui-card-content {
  padding-top: 15px !important;
}

/* Phone Input */
.react-tel-input .form-control {
  width: 100%;
  height: 56px;
  background-color: transparent;
  color: black;
}

