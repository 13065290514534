body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flickity-page-dots {
  bottom: -20px !important;
  z-index: 1 !important;
}

.flickity-page-dots
.dot {
    /* background: #ffffff !important; */
    opacity: 1 !important;
}

.flickity-page-dots
.dot.is-selected {
    background: #ff0b0a !important;
}

.carousel {
  /* background: black !important; */
}

@media only screen and (max-width : 540px) {
  .flickity-page-dots {
    bottom: 5px !important;
  }

  .flickity-page-dots
  .dot {
    width: 7px;
    height : 7px;
  }
}