.al-vid-cont {
  color: #fff;
  background: black;
}
.al-vid-cont h2 {
  padding: 1% 2%;
}
.al-vid-cont .play-vid {
  /* padding: 0; */
  margin: 0 auto;
  padding-top: 4.5rem;
  /* width: 1000px !important; */
  width: 1188px !important;
  /* height: 500px !important; */
  height: 668px !important;
}
#video-details {
  display: flex;
  justify-content: space-between;
  margin: 15px 20%;
}
#video-details-left {
  width: 70%;
  overflow-wrap: anywhere;
}
#video-details-left span {
  display: flex;
}
#video-details-right span {
  display: flex;
}
#video-details-left h3,
p {
  padding-left: 3px;
}
#video-details-right p {
  padding-left: 3px;
}
/* animation color */
.MuiSkeleton-wave::after {
  background-color: rgba(255, 255, 255, 0.04) !important;
}
@media only screen and (max-width: 1679px) {
  #video-details {
    margin: 15px 15%;
  }
}
@media only screen and (max-width: 1490px) {
  #video-details {
    margin: 15px 12%;
  }
}
@media only screen and (max-width: 1270px) {
  .al-vid-cont h2 {
    padding: 20px 40px;
  }
  #video-details {
    margin: 15px 8%;
  }
}

@media only screen and (max-width: 1047px) {
  .al-vid-cont .play-vid {
    width: 95% !important;
  }
  #video-details {
    margin: 15px 5%;
    font-size: 20px;
  }
  #video-details h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .al-vid-cont .play-vid {
    height: 40vh !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .al-vid-cont .play-vid {
    width: 100% !important;
  }
  .al-vid-cont h2 {
    padding: 10px 30px;
  }
  #video-details {
    margin: 15px 3%;
  }
}
@media only screen and (max-width: 540px) {
  .al-vid-cont h2 {
    padding: 0px 15px;
    margin: 0;
  }
  #video-details {
    font-size: 12px;
  }
  #video-details h3 {
    font-size: 17px;
  }
}
