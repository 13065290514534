.MuiAppBar-colorPrimary {
  background-color: #15171c !important;
}
.MuiIconButton-label {
  color: white !important;
}

.MuiList-root {
  color: white !important;
  background-color: #15171c !important;
}
.MuiPaper-root
  .MuiDrawer-paper
  .makeStyles-drawerClose-88
  .MuiDrawer-paperAnchorLeft
  .MuiDrawer-paperAnchorDockedLeft
  .MuiPaper-elevation0 {
  background-color: #15171c !important;
}
a {
  text-decoration: none !important;
}
@media (min-width: 600px) {
  .makeStyles-toolbar-9 {
    min-height: 0px !important;
  }
}
.MuiListItemText-root {
  margin-bottom: 23px !important;
}

