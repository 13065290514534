* {
  box-sizing: border-box;
}

.disp-hide {
  display: none;
}

.user-home {
  width: 100%;
  padding-top: 70px;
  overflow: auto;
  background: white;
    /* background: linear-gradient(to bottom, #fff 10%, #23b5b5 90%); */
    color: #000;
  min-height: 100vh;
  /* -webkit-overflow-scrolling: touch; */
}

.carousel-title {
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 40px;
  text-align: center;
  font-weight: 900;
  font-size: 1.6rem;
  color: #ffffff;
}

.carousel-shadow-bottom {
  width: 100%;
  height: 25%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.01)
  );
}

.carousel-shadow-top {
  width: 100%;
  height: 12%;
  position: absolute;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.01)
  );
}

.pos-rel {
  position: relative;
}

.bot {
  position: relative;
  bottom: -125px;
}

.categories {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.left, .right {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -388%;
  z-index: 1;
  display: none;
  transition: display 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: display 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.left {
  left: 0%;
}

.right {
  right: 0%;
}

.left:hover,
.right:hover {
  cursor: pointer;
}

.unhide {
  display: block;
}

.video-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  display: none;
}

.cardtitle,
.card-duration {
  position: absolute;
  color: #ffffff;
}

.carousel-img {
  width: 65vw;
  /* object-fit: contain;
  max-height: 600px; */
}

.this {
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: color;
}

.this:hover {
  color: grey;
  cursor: pointer;
}

.cat-name {
  /* position: relative; */
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  padding: 0% 1%;
  margin-top: 1.3%;
  font-weight: 700;
  background: transparent;
  color: #000000;
}

.cardtitle {
  font-size: 15px;
  font-weight: 600;
  bottom: 4%;
  z-index: 1;
  text-align: center;
  width: 290px;
}

.cardtitle-shadow {
  width: 290px;
  height: 25%;
  position: absolute;
  bottom: 11%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.01)
  );
}

.card-duration {
  display: none;
  top: 9%;
  right: 0;
  font-size: 0.7rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 1px;
}

.video:hover .card-duration {
  display: block;
}

.category {
  margin-top: 0.3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 210px;
  overflow: hidden;
  /* user-select: none; */
}

.side-space {
  transform: translateX(250px);
}

.side-space-1 {
  transform: translateX(-320px);
}

.slob {
  width: 220px;
  height: 100%;
}

.child {
  width: max-content;
  background: transparent;
  display: flex;
  flex-direction: row;
  height: 100%;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* -webkit-overflow-scrolling: touch; */
}

.child.drag {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.video {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform;
}

.video:hover {
  transform: scale(1.15);
}

.video:hover .video-btn {
  display: block;
}

.img-thumb {
  width: 290px;
  object-fit: contain;
}

.outer-video {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;

}

@media only screen and (max-width: 540px) {
  .cardtitle {
    font-size: 10px;
    width: 170px;
    bottom : 2%;
  }

  .cardtitle-shadow {
    width: 170px;
    bottom: 10%;
  }

  .card-duration {
    font-size: 0.6rem;
  }

  .img-thumb {
    width: 170px;
  }

  .video-btn {
    width: 30px;
    height : 30px;
  }

  .left, .right {
    width: 30px;
    height: 30px;
    bottom: -350%;
  }

  .category {
    height : 120px;
  }

  .outer-video {
    margin: 0px 8px;
  }

  .carousel-img {
    width : 80vw; 
  }

  .carousel-title {
    font-size: 15px;
    bottom: 17px;
  }

  .cat-name {
   font-size: 14px;
   margin-top: 20px; 
  }

  .bot {
    bottom: -72px;
  }
}