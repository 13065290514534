@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
.usersigninContainer h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}
.usersigninContainer table tr :hover {
  background-color: rgb(221, 221, 221);
}
.signinContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  flex-direction: column;
}
.heading{
    font-family: 'Italianno', cursive;
    font-size: 60px;
}
.inputfields {
  width: 200px;
  margin-top: 70px;
  margin-left: 35%;
  padding: 20px;
  
}
.inputfield:focus{
    outline: none;
}
.inputfield{
    padding: 18px;
    border: none;
    margin:3px;
    height: 15px;
    width:400px;
    font-size: 16px;
    background-color: rgb(244, 244, 243);
    outline: none;
    text-decoration: none;
    color: rgb(76, 82, 100);
    border-radius: 5px;
}

.submitButtons{
    padding: 10px;
    width: 400px;
   border:none;
  
    font-size: 16px;
    color:white;
    background-color: rgb(69, 106, 221);
}
