.custom-image-input {
  position: absolute;
  color: transparent;
  cursor: pointer;
  width: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  height: calc(1em + 0.75rem);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-image-input::-webkit-file-upload-button {
  visibility: hidden;
}