.firebaseui-card-header h1 {
  color: black !important;
}

.firebaseui-card-actions button {
  width: auto !important;
}
.mdl-textfield--floating-label .mdl-textfield__label {
  margin: 0px !important;
  font-size: 1rem;
  padding-left: 2px;
}
.firebaseui-card-content {
  padding-top: 15px !important;
}

/* Phone Input */
.react-tel-input .form-control {
  width: 100%;
  height: 56px;
  background-color: transparent;
  color: black;
}
