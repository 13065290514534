.main-footer {
  background-color: black;
  color: white;
  /* margin-top: 3em; */
  padding: 100px 0;
}
.main-footer a {
  color: white !important;
  font-size: 1rem !important;
  text-decoration: none !important;
}
.footer-bottom {
  text-align: center;
}
.social-icons {
  margin-left: 1.2rem;
}
.social-icons a {
  font-size: 1.5rem !important;
  padding-right: 1rem;
  text-align: center;
}
