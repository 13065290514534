.course-item {
  height: 200px;
  width: 500px;
  display: flex;
  color: #000;
  background-color: rgb(231, 215, 121);
  margin-bottom: 20px;
}
.course-item-left {
  width: 50%;
  height: 100%;
}
.course-item-left img {
  width: 100%;
  height: 100%;
}
.course-item-right {
  position: relative;
  display: flex;
  padding: 5px;
  flex-direction: column;

  width: 100%;
}
.course-item-right-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
